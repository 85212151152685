// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"; // Import the Realtime Database module

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBlzEoRIPNkS3S6jcAhUCLODBtDDB6O5dE",
  authDomain: "streamerquest-36f51.firebaseapp.com",
  databaseURL: "https://streamerquest-36f51-default-rtdb.firebaseio.com",
  projectId: "streamerquest-36f51",
  storageBucket: "streamerquest-36f51.appspot.com",
  messagingSenderId: "320401724749",
  appId: "1:320401724749:web:f362d174291559cf979304",
  measurementId: "G-LFFWDZTRFR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database
const realtimeDb = getDatabase(app);

// Export the app and realtimeDb (for Realtime Database)
export { app, realtimeDb };
