import React from 'react';
import './TermsAndConditionsPage.css'; // Ensure this path is correct based on your project structure

const TermsAndConditionsPage = () => {
  return (
    <div className="TermsAndConditionsPage">
      <h1>Terms and Conditions for "Streamer Quest" Applications</h1>
      <p><strong>1. Introduction</strong></p>
      <p>Thank you for your interest in "Streamer Quest". By submitting your application, you agree to abide by these Terms and Conditions (the "Terms"). Please read them carefully before you submit your application. "Streamer Quest" reserves the right to change these Terms at any time without prior notice.</p>
      
      <p><strong>2. Eligibility</strong></p>
      <ul>
        <li>Be at least 18 years of age.</li>
        <li>Have a valid and active email address.</li>
        <li>Agree to be filmed and broadcasted during the course of the event.</li>
        <li>Comply with all the application requirements stated on the submission form.</li>
      </ul>

      <p><strong>3. Application Process</strong></p>
      <p>By participating in the application process, you agree to:</p>
      <ul>
        <li>Provide accurate and current information about yourself as prompted by the application form.</li>
        <li>Maintain the security of any non-public information regarding your application.</li>
        <li>Accept sole responsibility for all activities that occur under your application submission.</li>
      </ul>

      <p><strong>4. Privacy</strong></p>
      <p>Your privacy is important to us. The information collected during the application process will be used solely for the purpose of "Streamer Quest" participant selection. By agreeing to these terms, you consent to the collection, storage, and use of your personal information as outlined in our Privacy Policy.</p>

      <p><strong>5. Content and Conduct</strong></p>
      <p>Participants are expected to behave in a manner that is lawful, respectful, and suitable for a public audience. This includes, but is not limited to:</p>
      <ul>
        <li>No use of hateful, discriminatory, or obscene language and imagery.</li>
        <li>No promotion of illegal activities or substances.</li>
        <li>Adherence to the rules and guidelines of conduct as outlined by "Streamer Quest".</li>
      </ul>

      <p><strong>6. Intellectual Property</strong></p>
      <p>You retain all ownership rights to your content. However, by submitting an application, you grant "Streamer Quest" a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display the content provided in your application, in connection with the event and "Streamer Quest" promotion.</p>

      <p><strong>7. Selection and Participation</strong></p>
      <p>Selection for participation in "Streamer Quest" will be at the sole discretion of the event organizers. Being selected as a participant is subject to additional agreements and rules which will be provided to you at the time of selection.</p>

      <p><strong>8. Changes and Cancellation</strong></p>
      <p>"Streamer Quest" reserves the right to modify the event format, postpone, or cancel the event at any time for any reason without liability.</p>

      <p><strong>9. Limitation of Liability</strong></p>
      <p>"Streamer Quest" will not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.</p>

      <p><strong>10. Governing Law</strong></p>
      <p>These Terms will be governed by and construed in accordance with the laws of the jurisdiction where "Streamer Quest" is organized, without regard to its conflict of law provisions.</p>

      <p><strong>11. Acknowledgment and Agreement</strong></p>
      <p>By submitting your application, you acknowledge that you have read and understood these Terms and agree to be bound by them.</p>

      <p><strong>12. Contact Us</strong></p>
      <p>For any questions about these Terms, please contact us at info@streamerquest.com</p>
    </div>
  );
};

export default TermsAndConditionsPage;
