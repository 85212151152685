import React, { useState, useEffect } from 'react';
import './App.css';
import PopupForm from './PopupForm';
import VideoPlayer from './VideoPlayer';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import TermsAndConditionsPage from './TermsAndConditionsPage';

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isDropdownOpen && !event.target.closest('.dropdown') && !event.target.closest('.follow-link')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownOpen]);

  const onCameraClick = () => {
    console.log('Camera was clicked');
    // Additional actions when a camera is clicked can be implemented here.
  };

  return (
    <Router>
      <div className="App">
        <div className="menu-bar">
          <Link to="/">
            <img src="/Streamerquest.png" alt="StremerQuest Logo" className="kick-logo" />
          </Link>
          <div className="menu-links-container"> {/* Container to center menu links */}
            <div className="menu-links">
              <a href="#donate" className="menu-link">
                Donate
              </a>
              <div className="menu-link follow-link" onClick={toggleDropdown}>
                Follow
              </div>
              {isDropdownOpen && (
                <div className="dropdown">
                  <a href="https://kick.com/streamerquest" target="_blank" rel="noopener noreferrer" className="dropdown-item">StreamerQuest</a>
                  <a href="https://twitter.com/streamerquest" target="_blank" rel="noopener noreferrer" className="dropdown-item">Twitter</a>
                  <a href="https://instagram.com/streamerquest" target="_blank" rel="noopener noreferrer" className="dropdown-item">Instagram</a>
                  <a href="https://tiktok.com/@streamerquest" target="_blank" rel="noopener noreferrer" className="dropdown-item">TikTok</a>
                  <a href="https://youtube.com/@StreamerQuest" target="_blank" rel="noopener noreferrer" className="dropdown-item">YouTube</a>
                </div>
              )}
              <div className="menu-link" onClick={togglePopup}>
                Apply!
              </div>
            </div>
          </div>
          <div className="profile-icon"> {/* Placeholder for profile icon */}
            {/* Profile icon or related content goes here */}
          </div>
        </div>

        <Routes>
          <Route path="/" element={<VideoPlayer onCameraClick={onCameraClick} />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        </Routes>

        <footer className="App-footer">
          <span>
            © {new Date().getFullYear()} StreamerQuest. All Rights Reserved.
          </span>
          <Link to="/terms-and-conditions" className="footer-link">
            Terms and Conditions
          </Link>
        </footer>
      </div>
      {isPopupOpen && <PopupForm isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />}
    </Router>
  );
}

export default App;