import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './VideoPlayer.css';

const VideoPlayer = ({ onCameraClick }) => {
  const [mainCamera, setMainCamera] = useState({
    url: "https://player.kick.com/streamerquest",
    title: "Streamer Quest",
  });

  const [cameras, setCameras] = useState([
    { url: "https://player.kick.com/burgerplanet", title: "Burger Planet" },
    { url: "https://player.kick.com/kangjoel", title: "Kang Joel" },
    { url: "https://player.kick.com/cristravels", title: "Cris Travels" },
    { url: "https://player.kick.com/sam", title: "Sam Pepper" },
  ]);

  // Responsive design state
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Effect to handle window resize
  useEffect(() => {
    const updateMedia = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  // Camera click handler
  const handleCamClick = (index) => {
    const newMainCamera = cameras[index];
    const newCameras = [...cameras];
    newCameras[index] = mainCamera; // Swap main camera with the selected one
    setMainCamera(newMainCamera);
    setCameras(newCameras);
    if (onCameraClick) {
      onCameraClick(); // Call the provided onClick handler if any
    }
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  // Function to render camera previews depending on the screen size
  const renderCameraPreviews = () => {
    if (isMobile) {
      // Use Swiper for mobile view
      return (
        <Swiper slidesPerView={2} spaceBetween={10} loop={true}>
          {cameras.map((camera, index) => (
            <SwiperSlide key={camera.title}>
              <div className="cam-container" onClick={() => handleCamClick(index)}>
                <iframe
                  src={camera.url}
                  height="180"
                  width="320"
                  frameBorder="0"
                  scrolling="no"
                  allowFullScreen
                  webkitAllowFullScreen
                  mozAllowFullScreen
                  msAllowFullScreen
                  title={`${camera.title} Stream`}
                ></iframe>
                <div className="title">{camera.title}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      );
    } else {
      // Use a grid layout for non-mobile view
      return (
        <div className="side-cams">
          {cameras.map((camera, index) => (
            <div className="cam-container" onClick={() => handleCamClick(index)} key={camera.title}>
              <iframe
                src={camera.url}
                height="180"
                width="320"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                webkitAllowFullScreen
                mozAllowFullScreen
                msAllowFullScreen
                title={`${camera.title} Stream`}
              ></iframe>
              <div className="title">{camera.title}</div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <header className="App-header">
      <div className="main">
        <iframe
          src={mainCamera.url}
          height="720"
          width="1280"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          webkitAllowFullScreen
          mozAllowFullScreen
          msAllowFullScreen
          title={`${mainCamera.title} Stream`}
        ></iframe>
      </div>
      {renderCameraPreviews()}
    </header>
  );
};

export default VideoPlayer;
