import React, { useState, useRef, useEffect } from 'react';
import { getDatabase, ref, push, set } from 'firebase/database';
import './PopupForm.css';

const InputGroup = ({ label, type, name, placeholder, value, onChange, autoComplete }) => (
  <div className={`form-group ${name === 'birthday' || name === 'location' ? 'flex-input' : ''}`}>
    <label htmlFor={name}>{label}</label>
    <input
      id={name}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoComplete={autoComplete}
      required
    />
  </div>
);

function PopupForm({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    birthday: '',
    location: '',
    socialMedia: '',
    motivationLink: '',
  });
  const [showThankYou, setShowThankYou] = useState(false);
  const [showIntro, setShowIntro] = useState(true); // State to toggle the intro screen
  const formRef = useRef(null);

  useEffect(() => {
    if (isOpen && !showIntro) {
      const firstInput = formRef.current.querySelector('input');
      if (firstInput) firstInput.focus();
    }

    const handleEscape = (event) => {
      if (event.key === 'Escape') onClose();
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose, showIntro]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleStartApplication = () => {
    setShowIntro(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Calculate age from birthday
    const birthday = new Date(formData.birthday);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  
    // Check if age is less than 18
    if (age < 18) {
      alert("You must be at least 18 years old to submit this form.");
      return;
    }
  
    try {
      const db = getDatabase();
      const submissionsRef = ref(db, 'submissions');
      const newSubmissionRef = push(submissionsRef);
  
      await set(newSubmissionRef, formData);
      
      console.log("Document written with ID: ", newSubmissionRef.key);
      setShowThankYou(true);
      
      setTimeout(() => {
        onClose();
        setShowThankYou(false);
      }, 3000);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };  

  if (!isOpen) return null;

  if (showThankYou) {
    return (
      <div className="popup-form" role="dialog" aria-modal="true">
        <div className="popup-form-content">
          <h2>Thank You!</h2>
          <p>Your application has been submitted. We will get back to you soon.</p>
        </div>
      </div>
    );
  }

  if (showIntro) {
    return (
      <div className="popup-form" role="dialog" aria-modal="true">
        <div className="popup-form-content">
          <button className="close" onClick={onClose} aria-label="Close form">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2>Welcome to Streamer Quest</h2>
          <p>Join us on an epic journey where streamers and viewers collide!</p>
          <h3>About The Show</h3>
          <p>Streamer Quest is an interactive live stream series featuring emerging content creators living under one roof. With the audience in control of the show's direction, every moment is unpredictable and thrilling!</p>
          <h3>How It Works</h3>
          <p>Viewers vote on activities, influence challenges, and have the power to change the course of the streamers' fate. Your participation shapes the show!</p>
          <h3>Want to Join the Adventure?</h3>
          <p>If you're a streamer or an aspiring internet personality, this is your chance to shine. Apply now to become part of Streamer Quest!</p>
          <button onClick={handleStartApplication} className="start-application-btn">Start Application</button>
        </div>
      </div>
    );
  }

  return (
    <div className="popup-form" role="dialog" aria-modal="true">
      <div className="popup-form-content" ref={formRef}>
        <button className="close" onClick={onClose} aria-label="Close form">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2 id="formTitle">Apply for Streamer Quest</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <InputGroup
              label="Full Name"
              type="text"
              name="fullName"
              placeholder="John Doe"
              value={formData.fullName}
              onChange={handleChange}
              autoComplete="name"
            />
            <InputGroup
              label="Email"
              type="email"
              name="email"
              placeholder="john.doe@example.com"
              value={formData.email}
              onChange={handleChange}
              autoComplete="email"
            />
          </div>
          <div className="form-row">
            <InputGroup
              label="Birthday"
              type="date"
              name="birthday"
              placeholder="YYYY-MM-DD"
              value={formData.birthday}
              onChange={handleChange}
              autoComplete="bday"
            />
            <InputGroup
              label="Location"
              type="text"
              name="location"
              placeholder="New York, USA"
              value={formData.location}
              onChange={handleChange}
              autoComplete="address-level2"
            />
          </div>
          <div className="form-group">
            <label htmlFor="socialMedia">Social Media Profiles</label>
            <textarea
              id="socialMedia"
              name="socialMedia"
              placeholder="Enter each profile on a new line"
              value={formData.socialMedia}
              onChange={handleChange}
              autoComplete="off"
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="motivationLink">Motivation Video Link</label>
            <input
              id="motivationLink"
              type="text"
              name="motivationLink"
              placeholder="https://youtu.be/your-video"
              value={formData.motivationLink}
              onChange={handleChange}
              autoComplete="off"
              required
            />
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default PopupForm;
